import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Navbar from "../components/Dashboard/Navbar/Navbar"
import Header from "../components/Dashboard/Header/Header"
import "./style.css"
import Footer from "../components/Dashboard/Footer/Footer"
import ContactUs from "../components/ContactUs/ContactUs"
import TypesOfService from "../components/BackEndDevelopment/TypesOfService/TypesOfService"
import LessIcon from "../images/expand_less_black_24dp.svg"


export const query = graphql`
    query BackEndQuery {
    backEndDevelopment: contentfulBackEndDevelopment(slug: {eq: "/back-end-development"}) {
    id
    themeColor
    navbars {
      title
      to
      hRef
    }
    header {
      title
      description {
        description
      }
      actionImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      image {
         gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    service {
      title
      ServiceType {
        title
        serviceImage {
           gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
      footer {
      logo {
         gatsbyImageData(layout: FULL_WIDTH)
      }
      email
      phoneNumber
      footerHeading {
        title
        footerSection {
          slug
          text
        }
      }
      socialLink {
        icon {
           gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
     footerWaveColor {
      firstWaveColor
      secondWaveColor
    }
    copyright {
      title
    }
  }
}
    `

const BackEndDevelopment = ({ location, data: { backEndDevelopment } }) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  function scrollHandler() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }

  return (
    <>
      <SEO title="Home" />
      <Navbar navbar={backEndDevelopment.navbars} themeColor={backEndDevelopment.themeColor} />
      {/* <Header header={backEndDevelopment.header} /> */}
      <Header header={backEndDevelopment.header.reduce((p, c) => ({ ...p, ...c }), {})} />
      {/* <CustomHeader header={backEndDevelopment.header.reduce((p, c) => ({ ...p, ...c }), {})} /> */}
      <TypesOfService service={backEndDevelopment.service} themeColor={backEndDevelopment.themeColor} />
      <ContactUs themeColor={backEndDevelopment.themeColor} />
      <Footer footer={backEndDevelopment.footer} copyright={backEndDevelopment.copyright} />
      {/* <Footer footer={backEndDevelopment.footer} copyright={backEndDevelopment.copyright} footerWaveColor={backEndDevelopment.footerWaveColor} /> */}
      <div className="scroll-button-circle" onClick={scrollHandler}
           style={{ display: scrollPosition > 1000 ? "flex" : "none", background: backEndDevelopment.themeColor }}>
        <img src={LessIcon} alt="lessIcon" className="mb-0 less-icon" />
      </div>
    </>
  )
}

export default BackEndDevelopment
