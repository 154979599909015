import React, { useState } from 'react'
import './TypesOfService.css'
import net from '../../../images/Rectangle 313.png'
import node from '../../../images/Rectangle -1.png'
import mongo from '../../../images/Rectangle -2.png'
import sql from '../../../images/Rectangle -5.png'
import nosql from '../../../images/Rectangle -3.png'
import ms from '../../../images/Rectangle -4.png'
import cloud from '../../../images/Rectangle -6.png'
import real from '../../../images/Rectangle -7.png'
import lite from '../../../images/Rectangle -8.png'
import graphql from '../../../images/Rectangle 3133.png'
import api from '../../../images/Rectangle 31332.png'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';

export default function TypesOfService({ service, themeColor, ...props }) {
  return (
    <section className="types-of-service">
      <div className="dynamic-image img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="dynamic-image-second img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="container">
        <Fade bottom duration={1000}>
          <h2 className="main-heading-title text-center mb-0">{service.title}</h2>
          <div className="d-flex justify-content-center">
            <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
          </div>
        </Fade>
        <div className="row types-container">
          {service.ServiceType.map((p, i) => {
            return (
              <div className="col-12 col-md-6 col-lg-4 types-of-card">
                <div className="card position-relative">
                  <Zoom>
                    {/*<img src={p.serviceImage.fluid.src} className="types-img mb-0" alt=".net-img" />*/}
                  </Zoom>
                  <div className="d-flex justify-content-center">
                    <Roll left delay={i * 200} duration={200}>
                      <h2 className="types-title mb-0">{p.title}</h2>
                    </Roll>
                  </div>
                </div>
              </div>
            )
          }
          )}
        </div>
      </div>
    </section >
  )
}
